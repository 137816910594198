/* Disable sort-order for this specific package */
/* eslint-disable */
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { OktaAuth } from '@okta/okta-auth-js';
import { LoginCallback, SecureRoute, Security } from '@okta/okta-react';
import { config } from '@Utils/common';
import fetcher from '@Utils/fetcher';
/* eslint-disable */
import { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { SWRConfig } from 'swr';
import App from './components/App';
import { UserProvider } from './context/UserContext';
import * as serviceWorker from './serviceWorker';
import Themes from './themes';
import './themes/globals.css';
import './wdyr'; // <--- needs to be first import

const oktaAuth = new OktaAuth(config);

ReactDOM.render(
  <ThemeProvider theme={Themes.default}>
    <CssBaseline />
    <Router>
      <Security oktaAuth={oktaAuth}>
        <UserProvider>
          <SWRConfig value={{ fetcher }}>
            <Suspense fallback={<></>}>
              {[
                'analytics.shapesecurity.com',
                'prod-dot-shpsec-sic-dv.appspot.com',
                'shpsec-sic-dv.appspot.com',
                'shpsec-sic-dv.wl.r.appspot.com',
                'stage-dot-shpsec-sic-dv.appspot.com',
                'test-dot-shpsec-sic-dv.wl.r.appspot.com',
              ].some((hostname) =>
                window.location.hostname.includes(hostname)
              ) ? (
                <SecureRoute path="/" exact component={App} />
              ) : (
                <>
                  <Route path="/" exact component={App} />
                  <SecureRoute path="/login" exact component={App} />
                </>
              )}
              <Route path="/implicit/callback" component={LoginCallback} />
            </Suspense>
          </SWRConfig>
        </UserProvider>
      </Security>
    </Router>
  </ThemeProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
