// React
// Icons
// api
import { getDashboards } from '@Api/dashboardsApi';
import { BarChartIcon, ConstructionIcon, LinkIcon } from '@Components/Icons';
import SvgIcon from '@Components/SvgIcon';
import { useUserState } from '@Context/UserContext';
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faRobot,
  faUserNinja,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// MUI
import { Drawer, IconButton } from '@material-ui/core';
import { useLocalStorage } from 'beautiful-react-hooks';
// NPM
import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import { ReactComponent as FontAwesomeMonkeyIcon } from '../../images/font_awesome_monkey.svg';
// Components
import SidebarMenu from './components/SidebarMenu/SidebarMenu';
// Styles
import useStyles from './styles';

export default function Sidebar() {
  const [isSidebarOpen, setIsSidebarOpen] = useLocalStorage(
    'isSidebarOpen',
    true
  );
  const [dashboards, setDashboards] = useState([]);
  const classes = useStyles();
  const { user } = useUserState();

  useEffect(() => {
    if (user) {
      getDashboards(user.customerId, true).then((result) => {
        setDashboards(result);
      });
    }
  }, [user]);

  const defaultMenu = {
    groups: ['administrators'],
  };

  const defaultLink = {
    icon: <LinkIcon />,
    label: 'Link',
    url: '/app',
  };

  const menus = [
    {
      ...defaultMenu,
      groups: ['administrators'],
      links: [
        {
          ...defaultLink,
          icon: <FontAwesomeIcon icon={faRobot} size="lg" />,
          label: 'Bothunter',
          url: '/app/bothunter',
        },
        {
          ...defaultLink,
          icon: (
            <SvgIcon>
              <FontAwesomeIcon icon={faUserNinja} />
            </SvgIcon>
          ),
          label: 'FraudNinja',
          url: '/app/fraudninja',
        },
        {
          ...defaultLink,
          icon: <BarChartIcon />,
          label: 'KProxy Loader',
          url: '/app/kproxy-loader',
        },
        {
          ...defaultLink,
          icon: <ConstructionIcon />,
          label: 'Report Builder',
          url: '/app/report-builder',
        },
        {
          ...defaultLink,
          icon: (
            <SvgIcon>
              <FontAwesomeMonkeyIcon />
            </SvgIcon>
          ),
          label: 'VizMonkey',
          url: '/app/vizmonkey',
        },
      ],
    },
  ];

  return (
    <Drawer
      className={cx(classes.drawer, classes.sidebar, {
        [classes.drawerOpen]: isSidebarOpen,
        [classes.drawerClosed]: !isSidebarOpen,
      })}
      classes={{
        paper: cx(classes.drawerPaper, {
          [classes.drawerOpen]: isSidebarOpen,
          [classes.drawerClosed]: !isSidebarOpen,
        }),
      }}
      open={isSidebarOpen}
      variant="permanent"
    >
      <IconButton
        className={classes.drawerToggleButton}
        color="inherit"
        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
      >
        <FontAwesomeIcon
          icon={isSidebarOpen ? faAngleDoubleLeft : faAngleDoubleRight}
          size="sm"
        />
      </IconButton>
      {user &&
        menus
          .filter(
            (menu) => menu.groups.includes(user.group) && menu.links.length > 0
          )
          .map((menu) => (
            <SidebarMenu
              key={`${menu.title}-${menu.url}`}
              links={menu.links}
              title={menu.title}
              isSidebarOpen={isSidebarOpen}
            />
          ))}
    </Drawer>
  );
}
