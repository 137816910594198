// React
// Context
// Components
import AppHeader from '@Components/AppHeader';
import Sidebar from '@Components/Sidebar';
import { useIsAdminUser } from '@Context/UserContext';
import classnames from 'classnames';
import React, { lazy } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
// Styles
import useStyles from './styles';

// Pages
const Bothunter = lazy(() => import('@Pages/bothunter'));
const BothunterSignatures = lazy(() =>
  import('@Pages/bothunter/BothunterSignatures')
);
const FraudNinja = lazy(() => import('@Pages/fraudninja'));
const KProxyLoader = lazy(() => import('@Pages/kproxy-loader'));
const ReportBuilder = lazy(() => import('@Pages/report-builder'));
const VizMonkey = lazy(() => import('@Pages/vizmonkey'));

function Layout() {
  const classes = useStyles();
  const isAdminUser = useIsAdminUser();

  return (
    <div className={classes.root}>
      <AppHeader />
      <Sidebar />
      <main className={classnames(classes.main)}>
        <Switch>
          {isAdminUser && (
            <>
              <Route
                exact
                path={[
                  '/app/bothunter',
                  '/app/bothunter/predict',
                  '/app/bothunter/label',
                ]}
                component={Bothunter}
              />
              <Route
                path="/app/bothunter/label/:id"
                component={BothunterSignatures}
              />
              <Route
                path="/app/bothunter/predict/:id"
                component={BothunterSignatures}
              />
              <Route path="/app/fraudninja/:id?" component={FraudNinja} />
              <Route path="/app/kproxy-loader" component={KProxyLoader} />
              <Route path="/app/report-builder" component={ReportBuilder} />
              <Route path="/app/vizmonkey" component={VizMonkey} />
            </>
          )}
        </Switch>
      </main>
    </div>
  );
}

export default withRouter(Layout);
