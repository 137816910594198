// React
import { useSetUser } from '@Context/UserContext';
// Auth
import { useOktaAuth } from '@okta/okta-react';
import React, { useEffect } from 'react';
import {
  BrowserRouter,
  HashRouter,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
// Pages
import Error from '../pages/error';
// components
import Layout from './Layout';

export default function App() {
  const { oktaAuth, authState } = useOktaAuth();
  const setUser = useSetUser();

  useEffect(() => {
    if (!authState.isPending) {
      if (authState.isAuthenticated) {
        oktaAuth.getUser().then((user) => {
          user.group = user.customerId === '0' ? 'administrators' : 'customers';
          setUser({
            user,
            isAuthenticated: authState.isAuthenticated,
            accessToken: authState.accessToken?.accessToken,
          });
        });
      } else {
        const user = {
          customerId: '0',
          customerName: 'Shape Security',
          name: 'Admin User',
          group: 'administrators',
        };
        setUser({
          user,
          isAuthenticated: authState.isAuthenticated,
          acessToken: undefined,
        });
      }
    }
  }, [authState]);

  return (
    <>
      <HashRouter>
        <Switch>
          <Route
            exact
            path="/"
            render={() => <Redirect to="/app/bothunter" />}
          />
          <Route
            exact
            path="/app"
            render={() => <Redirect to="/app/bothunter" />}
          />
          <Route path="/app" component={Layout} />
          <Route component={Error} />
        </Switch>
      </HashRouter>
      <BrowserRouter>
        {authState.isAuthenticated && (
          <Route path="/login" render={() => <Redirect to="/" />} />
        )}
      </BrowserRouter>
    </>
  );
}
